<template>
  <div class="login-page">
    <div class="login-app">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginAuthWrapper',
}
</script>
